import React from "react";
import { Chip } from "@material-ui/core";

const MultipleSelectChips = ({ value, setValue, options }) => {
  const handleChange = (e, option) => {
    e.persist()
    const index = value.findIndex((item) => item.value._id === option.value._id);
    if (index > -1) {
      setValue(value.filter((item) => item.value._id !== option.value._id));
    } else {
      setValue([...value, option]);
    }
  };

  return (
    <div>
      <div style={{ marginTop: "10px" }}>
        {options.map((item) => {
          const isSelected = value.some((v) => v.value === item.value);
          return (
            <Chip
              key={item.value._id}
              label={item.label}
              onClick={(e) => handleChange(e, item)}
              style={{
                margin: "5px",
                backgroundColor: isSelected ? "#4b5cb5" : "#fff",
                color: isSelected ? "#fff" : "#4b5cb5",
                border: "1px solid #4b5cb5",
                boxShadow: isSelected ? "0px 2px 10px rgba(0, 0, 0, 0.2)" : "none",
                cursor: "pointer",
                transition: "all 0.3s ease",
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MultipleSelectChips;
